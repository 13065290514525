import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import SearchFilters from "../../components/SearchFilters/SearchFilters";
import Grid from "../../components/Grid/Grid";
import Loading from "../../components/Loading/Loading";
import { childrenTileMapper, fetchHandler, getClientApiURL } from "../../utils";
import _ from "lodash";
import "./VideoAndAudioCatalog.scss";

class VideoAndAudioCatalog extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      initialCatalog: undefined,
      catalog: undefined,
      loading: true,
      performances: {}
    }
  }

  componentDidMount() {
    this.fetchFiltersData();
  }

  onSearch = (filters, selectedFilter, reloadResults = true, reloadCategory = false) => {
    this.setState({
      filters,
      loading: reloadResults,
      categoryLoading: reloadCategory,
    });
    this.fetchFiltersData(undefined, filters, selectedFilter);
  }

  hasFilters = () => {
    const { filters } = this.state;
    return Object.values(filters).length > 0;
  }

  getFilterUrl = (filters) => {
    let url = `${getClientApiURL("/filter")}`;
    if (filters) {
      url = `${url}?`;
      for (const key in filters) {
        if (filters[key])
          url = url.concat(`&${key}=${filters[key]}`);
      }
    }
    return url;
  }

  fetchFiltersData = (operaName, filters, selectedFilter)  => {
    const { performances, initialCatalog } = this.state;
    let url;
    if (operaName) {
      url = `${getClientApiURL(`/filter/${encodeURIComponent(operaName.toLowerCase())}`)}`;
    } else {
      url = this.getFilterUrl(filters);
    }
    if (selectedFilter)
      url = url.concat(`&active=${selectedFilter}`);
      fetchHandler(url, {}, (response) => {
        if (operaName) {
          performances[operaName] = response;
          this.setState({
            performances
          });
        } else {
          if (!initialCatalog) {
            this.setState({
              initialCatalog : response,
              catalog: response,
            });
          }
          this.setState({
            catalog: response
          });
        }
        this.setState({
          loading: false,
          categoryLoading: false,
        });
      });
  }

  onExpandGrid = (operaName, gridExpanded) => {
    const { performances } = this.state;
    if (!gridExpanded && operaName && !performances[operaName]) {
      this.fetchFiltersData(operaName, undefined);
    }
  }

  getPerformancesGridInfo = (result) => {
    const { performances } = this.state;
    const performancesGrid = {
      header: result.name,
      videoCount: result.videoCount,
      audioCount: result.audioCount,
      tiles: !_.isEmpty(performances) && performances[result.name] ? childrenTileMapper(performances[result.name]) : []
    }
    return performancesGrid;
  }

  render() {
    const { loading, catalog, initialCatalog, categoryLoading } = this.state;
    const { header } = this.props;

    return (
      <div className="VideoAndAudioCatalog">
      <h2 className="VideoAndAudioCatalog-header">{header}</h2>
      <SearchFilters catalog={initialCatalog} filters={catalog && catalog.filters} categoryLoading={categoryLoading} onSearch={this.onSearch} />
      { loading && <Loading inline={true} label="Loading..." centered="true" /> }
      <div className="VideoAndAudioCatalog-filter-results">
        { this.hasFilters() &&
          <h3>Filtered Results</h3>
        }
        {
          catalog && catalog.results.map((result, index) => {
            const performancesGridInfo = this.getPerformancesGridInfo(result);
            return <Grid key={index} {...performancesGridInfo} expandGrid={false} onExpandGrid={this.onExpandGrid} />
          })
        }
      </div>
      </div>
    )
  }
};

export default compose(withRouter, withStore)(VideoAndAudioCatalog);
