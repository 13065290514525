import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import SearchInput from "../../components/SearchInput/SearchInput";
import Loading from "../../components/Loading/Loading";
import { pluralize, fetchHandler, getClientApiURL } from "../../utils";
import { SVG } from "../../assets/images";
import "./SearchFilters.scss";
import {BREAKPOINT_TABLET_PORTRAIT} from "../../utils/constants";

const filterMenuOptions = [
  { label: "Type", value: "types"},
  { label: "Opera", value: "operas"},
  { label: "Composer", value: "composer"},
  { label: "Artist", value: "artist"},
];

const mediaTypeDropDownMenuOptions = [
  { label: "All Types", value: ""},
  { label: "HD Video", value: "hd"},
  { label: "SD Video", value: "sd"},
  { label: "Audio Only", value: "audio"},
];

const filterNameMapping = {
  "operas" : "opera",
  "types" : "media_type",
  "composer" : "composer",
  "artist" : "artist"
}

class SearchFilters extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: undefined,
      query: undefined,
      filters: {},
      searchFilters: undefined,
      showCloseIconOnFocus: false,
      mobileFiltersVisible: false,
      searchLoading: false,
      selectedLetters: {},
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  getFilterUrl = (filters) => {
    let url = `${getClientApiURL("/filter?")}`;
    if (filters) {
      for (const key in filters) {
        if (filters[key])
          url = url.concat(`&${key}=${encodeURIComponent(filters[key])}`);
      }
    }
    return url;
  }

  fetchFiltersData = (filters)  => {
    const url = this.getFilterUrl(filters);
    this.setState({
      searchLoading: true,
    })
    fetchHandler(url, {}, (response) => {
      this.setState({
        searchResponse: response,
        searchLoading: false,
      });
    });
  }

  onSearch = (name) => {
    const {selectedFilter, filters} = this.state;
    const selectedFilterName = filterNameMapping[selectedFilter];

    if (name) {
      filters[selectedFilterName] = name;
    } else {
      delete filters[selectedFilterName];
    }

    this.setState({
      filters,
      query: name
    });

    this.props.onSearch(filters, selectedFilterName);
  }

  onChange = (name) => {
    const {selectedFilter, searchResponse} = this.state;
    const selectedFilterName = filterNameMapping[selectedFilter];
    const filters = {};

    this.setState({
      query: name,
      searchResponse: !name || (searchResponse && searchResponse.results !== undefined && !searchResponse.results.length) ? undefined : searchResponse
    });

    if (name !== "") {
      filters[selectedFilterName] = name;
      this.fetchFiltersData(filters);
    }
  }

  setSelectedFilter = (filterType) => {
    const { filters } = this.state;
    const selectedFilterName = filterNameMapping[filterType];

    if (this.state.selectedFilter !== filterType && Object.keys(filters).length > 0)
      this.props.onSearch(filters, selectedFilterName, false, true);

    this.setState({
      selectedFilter : this.state.selectedFilter !== filterType ? filterType : undefined,
    });

    this.clearSearch();
    this.toggleDropDownMenu(filterType);
  }

  showSelectedFilterResults = (letter) => {
    const { selectedFilter, selectedLetters } = this.state;
    this.setState({
      selectedLetters: {
        ...selectedLetters,
        [selectedFilter]: letter.toLowerCase(),
      },
    });
  }

  scrollToLetter = (letter) => {
    const filtersContainer = document.querySelector(".filters-container"),
      filterResultsList = filtersContainer.querySelector(".filter-results-list"),
      scrollItem = filterResultsList.querySelector(`#filterResultLetter-${letter.toLowerCase()}`);

    if (scrollItem) {
      const elemRect = filterResultsList.getBoundingClientRect(),
      containerRect = filtersContainer.getBoundingClientRect();
      filterResultsList.scrollTo({top: scrollItem.offsetTop - (elemRect.top - containerRect.top)});
    }
  }

  toggleDropDownMenu = (id) => {
    // @ts-ignore
    document.querySelectorAll(".filter-results-dropdown-menu").forEach((el) => {
      if (el.getAttribute("id") === id) {
        el.classList.toggle("hide");
        // @ts-ignore
        document.querySelector(`.filter-category-${id}`).classList.toggle("expanded");
      } else {
        el.classList.remove("hide");
      }
    });
  }

  onFocus = () => {
    this.setState({showCloseIconOnFocus: true});
  }

  clearSearch = () => {
    this.setState({
      query : '',
      showCloseIconOnFocus: false,
      searchResponse: undefined,
    });
  }

  clearFilters = () => {
    const filters = {};
    this.setState({
      filters
    })
    this.props.onSearch(filters);
  }

  getSelectedFilterValue = (label) => {
    const { filters } = this.state;
    const selectedLabel = label.toLowerCase();
    if (selectedLabel === "type") {
      const option = _.find(mediaTypeDropDownMenuOptions, function(option) { return option.value === filters["media_type"] });
      return option && option.label;
    } else {
      return filters[selectedLabel];
    }
  }

  isSelected = (value) => {
    const { selectedFilter, filters } = this.state;
    const selectedFilterName = filterNameMapping[selectedFilter];
    if (filters[selectedFilterName] === value) {
      return true;
    }
  }

  getFilterResultClassName = (name) => {
    const { selectedFilter, selectedLetters, filters } = this.state;
    const currentCatalogResults = this.props.filters[selectedFilter][selectedLetters[selectedFilter]];
    const selectedFilterName = filterNameMapping[selectedFilter];
    const selectedFilterValue = filters[selectedFilterName];
    let className;

    if (currentCatalogResults.indexOf(name) < 0) {
      className = "disabled";
    } else if (selectedFilterValue === name) {
      className = "selected";
    }
    return className;
  }

  onMobileCTAClick = () => {
    this.setState({
      mobileFiltersVisible: true,
    });
    document.querySelector("body").classList.add("modal-open");
  }

  onMobileBackClick = () => {
    this.setState({
      selectedFilter: undefined,
    });
  }

  onMobileCloseClick = () => {
    if (this.state.selectedFilter) {
      this.setState({
        selectedFilter: undefined,
      });
    }
    this.setState({
      mobileFiltersVisible: false,
    });
    document.querySelector("body").classList.remove("modal-open");
  }

  onResize = () => {
    if (window.innerWidth > BREAKPOINT_TABLET_PORTRAIT) {
      this.onMobileCloseClick();
    }
  }
  getSearchResultClassName = (name) => {
    const { selectedFilter, filters } = this.state;
    const selectedFilterResults = Object.values(this.props.filters[selectedFilter]);
    const currentCatalogResults = _.uniq(_.flatten(selectedFilterResults));
    const selectedFilterName = filterNameMapping[selectedFilter];
    const selectedFilterValue = filters[selectedFilterName];
    let className;

    if (selectedFilterValue === name) {
      className = "selected";
    } else if (currentCatalogResults.indexOf(name) < 0) {
      className = "disabled";
    }

    return className;
  }

  getMediaTypeClassName = (name) => {
    const { filters, selectedFilter } = this.state;
    const selectedFilterName = filterNameMapping["types"];
    const selectedFilterValue = filters[selectedFilterName];
    const currentCatalogResults = this.props.filters[selectedFilter];
    let className;

    if ((!selectedFilterValue && name === "") || selectedFilterValue === name) {
      className = "selected";
    } else if (name !== "" && currentCatalogResults.indexOf(name.toUpperCase()) < 0) {
      className = "disabled";
    }
    return className;
  }

  render() {
    const { selectedFilter, selectedLetters, query, showCloseIconOnFocus, mobileFiltersVisible, searchResponse, searchLoading } = this.state;
    const { filters, catalog, categoryLoading } = this.props;
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const initialFilters = catalog && catalog.filters;
    const searchFilters = searchResponse && searchResponse.filters && selectedFilter && searchResponse.filters[selectedFilter];
    const searchResults = searchResponse && searchResponse.results;
    const selectedFilterLetter = selectedLetters[selectedFilter];

    return (
      <div className="SearchFilters">
        <button className="filters-mobile-cta btn btn-transparent black" onClick={this.onMobileCTAClick}><span>Filters +</span></button>
        <div className={`filters-container ${mobileFiltersVisible ? "mobile-filters-visible" : ""}`}>
          { mobileFiltersVisible &&
            <div className="filters-container-title">
              <span className={`filters-container-title-back ${selectedFilter && "active"}`} onClick={this.onMobileBackClick}><SVG.LeftPointer className="filters-container-title-back-icon" />Back</span>
              <span className="filters-container-title-label">{selectedFilter ? pluralize(filterMenuOptions.find((el) => el.value === this.state.selectedFilter).label) : "Filters"}</span>
              <span className="filters-container-title-close" onClick={this.onMobileCloseClick}>Close<SVG.Close className="filters-container-title-close-icon" /></span>
            </div>
          }
          {
            (!mobileFiltersVisible || !selectedFilter) &&
            <ul className="filter-categories">
              { filterMenuOptions.map((option, index) => {
                  const selectedFilterValue = this.getSelectedFilterValue(option.label);
                  return (
                    <li key={index} className={`filter-category-${option.value}${selectedFilter ? selectedFilter !== option.value ? " inactive" : " active" : ""} expanded`} onClick={() => this.setSelectedFilter(option.value)}>
                      <span>{option.label}</span>
                      <span className={selectedFilterValue ? "has-selected-filter" : ""}>{selectedFilterValue || `All ${pluralize(option.label)}`}</span>
                    </li>
                  )
                })
              }
              { (((query || Object.keys(this.state.filters).length) && !showCloseIconOnFocus) || (mobileFiltersVisible && !selectedFilter)) &&
                <li className={`clear-filters ${query || Object.keys(this.state.filters).length ? "active" : ""}`} onClick={this.clearFilters}>Clear Filters</li>
              }
            </ul>
          }
          { selectedFilter &&
          <div className="filter-results">
            {categoryLoading && <Loading inline={true}/>}
            {!categoryLoading &&
            <React.Fragment>
              {selectedFilter === "types" &&
                <ul className="filter-results-dropdown-menu" id="types">
                  {
                    mediaTypeDropDownMenuOptions.map((option, index) => {
                      return <li key={index} className={this.getMediaTypeClassName(option.value)} onClick={() => this.onSearch(option.value)}>{option.label}</li>
                    })
                  }
                </ul>
              }
              {selectedFilter !== "types" &&
                <div className={`filter-results-dropdown-menu ${showCloseIconOnFocus ? "search-results" : ""}`} id={selectedFilter}>
                  <SearchInput key={selectedFilter} onFocus={this.onFocus}
                    placeholder={`Search ${selectedFilter !== "operas" ? pluralize(selectedFilter) : selectedFilter}`}
                    onChange={this.onChange} onClearSearch={this.clearSearch} showCloseIconOnFocus={showCloseIconOnFocus}
                    error={searchResults && searchResults.length === 0} errorText="No results"
                  />
                  {searchLoading && <Loading inline={true} />}
                  {showCloseIconOnFocus && searchResults && query &&
                    <ul className="filter-results-list search-results-list">
                      {
                        Object.keys(searchFilters).map(function(key) {
                          const isInAlpha = alpha.indexOf(key.toUpperCase().charCodeAt(0)) !== -1;
                          return searchFilters[key].map(function(name, index) {
                            return <li id={isInAlpha && index === 0 ? `filterResultLetter-${key}` : ""} key={index} className={this.getSearchResultClassName(name)}><span onClick={() => this.onSearch(name)}>{name}</span>{this.isSelected(name) && <SVG.Close className="close-icon" onClick={() => this.onSearch()} />}</li>
                          }.bind(this))
                        }.bind(this))
                      }
                    </ul>
                  }
                  <ul className="alpha-list">
                    <li className="selected" onClick={() => this.onSearch()}>All</li>
                    {
                      alpha.map((str, index) => {
                        const letter = String.fromCharCode(str);
                        const selectedFilterItems  = mobileFiltersVisible && searchFilters ? searchFilters[letter.toLowerCase()] : filters[selectedFilter][letter.toLowerCase()];
                        return <li key={index} className={!selectedFilterItems || !selectedFilterItems.length ? 'disabled' : (selectedFilterLetter === letter.toLowerCase()) ? "active" : ""} onClick={!mobileFiltersVisible ? () => this.showSelectedFilterResults(letter) : () => this.scrollToLetter(letter)}>{letter}</li>
                      })
                    }
                  </ul>
                  {!mobileFiltersVisible && filters[selectedFilter][selectedFilterLetter] && !showCloseIconOnFocus &&
                    <ul className="filter-results-list">
                      {
                        initialFilters[selectedFilter][selectedFilterLetter].map(function(name, index){
                          return <li className={this.getFilterResultClassName(name)} key={index}><span onClick={() => this.onSearch(name)}>{name}</span>{this.isSelected(name) && <SVG.Close className="close-icon" onClick={() => this.onSearch()} />}</li>
                        }.bind(this))
                        }
                      </ul>
                    }
                    {mobileFiltersVisible && filters[selectedFilter] &&
                      <ul className="filter-results-list">
                      {
                        Object.keys(filters[selectedFilter]).map(function(key) {
                          const isInAlpha = alpha.indexOf(key.toUpperCase().charCodeAt(0)) !== -1;
                          return filters[selectedFilter][key].map(function(name, index) {
                            return <li className={this.isSelected(name) ? "selected" : ""} id={isInAlpha && index === 0 ? `filterResultLetter-${key}` : ""} key={index} onClick={() => this.onSearch(name)}><span>{name}</span></li>
                          }.bind(this))
                        }.bind(this))
                      }
                      {
                        Object.keys(initialFilters[selectedFilter]).map(function(key) {
                          return initialFilters[selectedFilter][key].filter(function(el) {
                            return !filters[selectedFilter][key] || filters[selectedFilter][key].indexOf(el) === -1;
                          }).map(function(name, index) {
                            return <li className="disabled" key={index}><span onClick={() => this.onSearch(name)}>{name}</span>{this.isSelected(name) && <SVG.Close className="close-icon" onClick={() => this.onSearch()} />}</li>
                          }.bind(this))
                        }.bind(this))
                      }
                      </ul>
                    }
                  </div>
                }

              </React.Fragment>
              }
            </div>
          }
        </div>
      </div>
    )
  }
};

export default compose(withRouter, withStore)(SearchFilters);
