import React from "react";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import SearchInput from "../../components/SearchInput/SearchInput";
import Fetch from "../../components/Fetch/Fetch";
import Grid from "../../components/Grid/Grid";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import Loading from "../../components/Loading/Loading";
import { SVGImage } from "../../assets/images";
import { pluralize, childrenTileMapper, getClientApiURL } from "../../utils";
import "./SearchPage.scss";

class SearchPage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(props.location.search);
    this.state = {
      query: parsed.q || undefined
    }
  }

  onSearch = (query) => {
    const { history, location } = this.props;
    this.setState({ query });
    history.replace(`${location.pathname}?q=${query}`);
  }

  clearSearch = () => {
    const { history, location } = this.props;
    this.setState({ query: undefined });
    history.replace(location.pathname);
  }

  getResultsCount = (results) => {
    let counts = [];
    _.forOwn(results, function(value, key) {
      if (value.total > 0) {
        const label = (key === "people" ? "person" : key);
        counts.push({
          label: (value.total === 1 ? label : label !== "audio" ? pluralize(label) : label),
          count: value.total
        });
      }
    });
    return counts;
  }

  getComponents = (results) => {
    let components = [];
    _.forOwn(results, function(value, key) {
      if (value.total > 0) {
        const grid = {
          type: 'grid',
          header: key,
          tiles: value.results.length > 0 && childrenTileMapper(value.results)
        }
        components.push(<Grid key={key} {...grid} />)
      }
    });
    return components;
  }

  render() {
    
    const { query } = this.state;
    const { history } = this.props;
    const url = query ? `${getClientApiURL(`/search/${encodeURIComponent(query.toLowerCase())}`)}` : getClientApiURL(process.env.REACT_APP_HOMEPAGE_URL);

    return (
      <Fetch
        url={url}
        addMiddlewareHeaders
        options={{ credentials: "include" }}
        authzBeforeRequest
        >
        {(loading, errors, data) => {
          const resultCounts = data && this.getResultsCount(data);
          return (
            errors ?
            <ErrorOverlay /> :
            <div className="SearchPage">
              <div className="back-btn" onClick={() => history.goBack()}><img src={SVGImage.BackArrow} alt="Back Arrow Icon" />Back</div>
              <div className="SearchPage-header">
                {!loading && query && data &&
                  <div className="search-results-info">
                    { resultCounts.length > 0 &&
                      <React.Fragment>
                        <p>Search results for <span>‘{query}’</span></p>
                        <p className="search-results-info-media-type-counts">
                          {
                            resultCounts.map((result, index) => {
                              return <span key={index}>{result.count} {result.label}</span>
                            })
                          }
                        </p>
                      </React.Fragment>
                    }
                    { resultCounts.length === 0 &&
                      <p>Sorry, there are no results for <span>‘{query}’</span></p>
                    }
                  </div>
                }
                <SearchInput autoFoucs={true} onChange={this.onSearch} onClearSearch={this.clearSearch} error={!loading && query && !data} />
              </div>
              { loading && query && <Loading /> }
              { query && data && this.getComponents(data)}
            </div>
          )
        }}
      </Fetch>
    )
  }
};

export default compose(withRouter, withStore)(SearchPage);
